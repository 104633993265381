@charset "UTF-8";
@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0; }
  100% {
    display: block;
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1; }
  99% {
    display: block;
    opacity: 0; }
  100% {
    display: none;
    opacity: 0; } }

@keyframes drawLine {
  0% {
    stroke-dashoffset: 3000;
    fill-opacity: 0; }
  30% {
    fill-opacity: 0; }
  35% {
    fill-opacity: 1; }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1; } }

@keyframes curtain {
  0% {
    left: 0;
    width: 0; }
  50% {
    left: 0;
    width: 100%; }
  51% {
    left: auto;
    right: 0;
    width: 100%; }
  100% {
    left: auto;
    right: 0;
    width: 0; } }

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

/**
*
*	common properties
*
**/
html {
  font-size: 62.5%; }

body {
  color: #333;
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

img, a {
  border: none;
  outline: none; }

label {
  cursor: pointer; }

p {
  line-height: 1.6em; }

main {
  display: block; }

.cols::after, .clearfix::after {
  content: "";
  display: block;
  clear: both; }

ol, ul {
  list-style: none; }

a:link, a:visited {
  color: #0f2f68;
  text-decoration: none;
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }

a:hover, a:active {
  opacity: 0.7; }

* {
  box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

ins {
  display: inline-block;
  vertical-align: bottom;
  background-color: transparent;
  color: inherit;
  text-decoration: none; }

.no-scroll {
  overflow-y: hidden; }

.modal-hidden-contents {
  display: none; }

#modal-base-layer {
  background: #000;
  color: #000;
  display: none;
  position: fixed;
  _position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100px;
  z-index: 9900;
  text-align: center; }

#modal-contents-layer {
  display: none;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9901; }
  #modal-contents-layer #modal-iscroll-container-inner {
    overflow: hidden; }

button {
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  button:hover {
    opacity: 0.7; }

.tac {
  text-align: center; }

.fcr {
  color: #ff0000; }

.fwb {
  font-weight: bold; }

.hidden, .sp-elem, .sp-il-elem {
  display: none; }

area:focus {
  outline: none;
  border: none; }

.table {
  display: table; }

.table-cell {
  display: table-cell;
  vertical-align: bottom; }

.cols {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }
  .cols .col {
    padding: 0 20px;
    width: 50%; }
  .cols.reverse {
    flex-flow: row-reverse; }

.container {
  position: relative;
  margin: 0 auto;
  padding: 70px 20px;
  max-width: 1240px; }

.breadcrumbs {
  background-color: #f5f5f5; }
  .breadcrumbs .container {
    padding-top: 16px;
    padding-bottom: 16px; }
  .breadcrumbs ul {
    display: flex;
    align-items: center; }
    .breadcrumbs ul li {
      margin-right: 7px;
      color: #333;
      font-size: 1.3rem; }
      .breadcrumbs ul li::after {
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 1px;
        margin-left: 7px;
        width: 8px;
        height: 12px;
        background: url(../images/icon_arrow_02.svg) no-repeat left top;
        content: ""; }
      .breadcrumbs ul li:first-of-type {
        height: 13px; }
        .breadcrumbs ul li:first-of-type a {
          overflow: hidden;
          position: relative;
          top: -2px;
          width: 16px;
          height: 16px;
          text-indent: -9999px;
          background: url(../images/icon_home_01.svg) no-repeat left top;
          background-size: contain; }
      .breadcrumbs ul li:last-of-type::after {
        display: none; }
    .breadcrumbs ul a {
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul a:link, .breadcrumbs ul a:visited {
        color: #333; }

h2 {
  position: relative;
  margin-bottom: 35px;
  color: #333;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.05em; }

.button-wrapper {
  text-align: center; }

.pagination {
  text-align: center; }
  .pagination .group {
    display: inline-block;
    font-size: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.1), 0 1px 14px 2px rgba(0, 0, 0, 0.1), 0 1px 5px -3px rgba(0, 0, 0, 0.1); }
    .pagination .group *:first-of-type {
      border-radius: 4px 0 0 4px; }
  .pagination .current,
  .pagination a:link,
  .pagination a:visited {
    display: inline-block;
    vertical-align: top;
    padding: 12px 0 12px 0;
    width: 40px;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: #fff;
    border-right: 1px solid #efefef; }
  .pagination .current {
    color: #0038d0; }
  .pagination a:last-of-type {
    border-radius: 0 4px 4px 0; }
  .pagination a:hover, .pagination a:active {
    opacity: 1;
    background-color: #eff4fc; }
  .pagination img {
    position: relative;
    top: -1px;
    width: auto;
    height: 12px; }

.box-list-01 {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }
  .box-list-01 li {
    margin: 0 10px 20px 10px;
    width: calc(33.33% - 20px);
    background-color: #f4f4f4; }
    .box-list-01 li p {
      padding: 16px;
      font-size: 1.4rem;
      font-weight: 500; }

.dl-table {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5em;
  border-bottom: 1px solid #ddd; }
  .dl-table > dt {
    padding: 16px;
    width: 160px;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #f4f4f4; }
  .dl-table > dd {
    padding: 16px;
    width: calc(100% - 160px);
    border: 1px solid #ddd;
    border-left: none;
    border-bottom: none; }

.list-01 {
  margin-top: 16px;
  margin-left: 0.8em; }
  .list-01 li {
    margin-bottom: 0.5em;
    line-height: 1.5em;
    text-indent: -0.8em; }
    .list-01 li::before {
      position: relative;
      top: -3px;
      margin-right: 0.3em;
      color: #3d70fc;
      font-size: 10px;
      content: "●"; }
    .list-01 li strong {
      color: #fff;
      background-color: #3d70fc; }

.arrow-button-01 {
  display: inline-block;
  color: #0f2f68; }
  .arrow-button-01:link, .arrow-button-01:visited {
    display: inline-block;
    color: #0f2f68; }
  .arrow-button-01 .arrow {
    display: inline-block;
    position: relative;
    top: -4px;
    margin-left: 5px;
    width: 20px;
    height: 1px;
    line-height: 0;
    font-size: 0;
    background-color: #0f2f68; }
    .arrow-button-01 .arrow::before {
      display: block;
      position: absolute;
      right: 0;
      top: -3px;
      height: 1px;
      width: 8px;
      background-color: #0f2f68;
      content: "";
      transform: rotate(45deg); }
    .arrow-button-01 .arrow::after {
      display: block;
      position: absolute;
      right: 0;
      top: 3px;
      height: 1px;
      width: 8px;
      background-color: #0f2f68;
      content: "";
      transform: rotate(-45deg); }
  .arrow-button-01.white {
    color: #fff; }
    .arrow-button-01.white .arrow {
      background-color: #fff; }
      .arrow-button-01.white .arrow::before, .arrow-button-01.white .arrow::after {
        background-color: #fff; }
  .arrow-button-01.reverse {
    display: inline-block;
    padding-right: 5px;
    padding-left: 15px;
    text-align: right; }
    .arrow-button-01.reverse.hidden {
      display: none; }
    .arrow-button-01.reverse .arrow {
      position: relative;
      left: -15px; }
      .arrow-button-01.reverse .arrow::before {
        left: 0;
        top: -3px;
        transform: rotate(-45deg); }
      .arrow-button-01.reverse .arrow::after {
        left: 0;
        top: 3px;
        transform: rotate(45deg); }

.arrow-button-02 {
  display: inline-block;
  padding-right: 15px;
  padding-left: 5px; }
  .arrow-button-02 .arrow {
    display: block;
    position: relative;
    margin: 3px auto 0 auto;
    width: 1px;
    height: 15px;
    background-color: transparent; }
    .arrow-button-02 .arrow::before {
      display: block;
      position: absolute;
      right: -1px;
      bottom: 1px;
      height: 2px;
      width: 8px;
      background-color: #000;
      content: "";
      transform: rotate(45deg); }
    .arrow-button-02 .arrow::after {
      display: block;
      position: absolute;
      left: -1px;
      bottom: 1px;
      height: 2px;
      width: 8px;
      background-color: #000;
      content: "";
      transform: rotate(-45deg); }

.arrow-button-03:link, .arrow-button-03:visited {
  color: #00a27a; }

.arrow-button-03::after {
  display: inline-block;
  margin-left: 5px;
  width: 8px;
  height: 10px;
  background: url(../img/icon_arrow_02.svg) no-repeat left top;
  content: "";
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 7px; }

.swiper-pagination-bullet {
  width: 40px;
  height: 2px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.8; }

.swiper-pagination-bullet-active {
  background-color: #0f2f68; }

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 30px; }

.bg-gray {
  background-color: #f8f8f8; }

.button-01 {
  display: block;
  color: #fff;
  background-color: #47b510;
  padding: 20px 0 24px 0;
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
  .button-01:link, .button-01:visited {
    display: block;
    color: #fff;
    background-color: #47b510;
    padding: 20px 0 24px 0;
    font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
  .button-01:hover, .button-01:active {
    opacity: 1;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 3px 5px -3px rgba(0, 0, 0, 0.4); }
  .button-01:focus {
    outline: none; }

/**
*
*	modules
*
**/
#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  border-top: 8px solid #0f2f68;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1); }
  #header .container {
    height: 114px;
    padding: 0 20px; }
  #header a:link, #header a:visited {
    color: #333; }
  #header h1 {
    position: absolute;
    left: 20px;
    top: 5px;
    font-size: 1rem;
    font-weight: normal; }
  #header .logo {
    display: inline-block;
    margin-top: 34px; }
  #header #g-nav {
    float: right;
    margin-top: 64px;
    margin-right: 170px;
    letter-spacing: 0.05em; }
    #header #g-nav ul {
      font-size: 0; }
    #header #g-nav li {
      display: inline-block;
      vertical-align: top;
      padding-bottom: 16px;
      font-size: 1.4rem;
      text-align: center; }
      #header #g-nav li::before {
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 0;
        width: 1px;
        height: 35px;
        background-color: #ddd;
        content: ""; }
    #header #g-nav .has-child > a::after {
      display: inline-block;
      position: relative;
      top: -2px;
      margin-left: 5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: #333 transparent transparent transparent;
      content: ""; }
    #header #g-nav li a {
      display: inline-block;
      vertical-align: top;
      padding: 8px 20px 8px 20px;
      line-height: 1.4em; }
    #header #g-nav li span {
      color: #00a27a;
      font-size: 10px; }
    #header #g-nav .child {
      display: none;
      position: absolute;
      left: 0;
      top: 114px;
      width: 500%;
      padding: 40px 50% 10px 50%;
      margin: 0 -50%;
      text-align: left;
      background-color: #fff;
      -webkit-box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.1); }
      #header #g-nav .child .inner {
        max-width: 1200px; }
      #header #g-nav .child .label {
        float: left;
        margin-bottom: 50px;
        padding-left: 20px;
        color: #0f2f68;
        font-size: 1.8rem;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        width: 310px; }
        #header #g-nav .child .label span {
          display: block;
          color: #333;
          font-size: 1rem; }
      #header #g-nav .child ul {
        float: left;
        position: relative;
        padding-right: 20px;
        width: calc(100% - 310px); }
        #header #g-nav .child ul::before {
          display: block;
          position: absolute;
          left: -40px;
          top: 0;
          width: 1px;
          height: 24px;
          background-color: #ccc;
          content: ""; }
        #header #g-nav .child ul li {
          display: block;
          float: left;
          margin-bottom: 30px;
          padding-bottom: 0;
          width: 33.33%;
          text-align: left; }
          #header #g-nav .child ul li::before, #header #g-nav .child ul li::after {
            display: none; }
          #header #g-nav .child ul li a {
            display: block;
            padding: 0; }
    #header #g-nav .sp-elem {
      display: none; }
  #header .sub-nav {
    position: absolute;
    top: 0;
    right: 190px; }
    #header .sub-nav ul {
      font-size: 0; }
      #header .sub-nav ul li {
        display: inline-block;
        vertical-align: top;
        font-size: 1.2rem;
        line-height: 34px; }
        #header .sub-nav ul li img {
          position: relative;
          top: -1px;
          margin-right: 5px;
          vertical-align: middle;
          width: 15px; }
        #header .sub-nav ul li a {
          padding: 0 10px; }
        #header .sub-nav ul li ul li {
          display: list-item; }
  #header .contact-info {
    position: absolute;
    right: 20px;
    top: 5px;
    width: 170px;
    text-align: center; }
    #header .contact-info .text-01 {
      font-size: 1.1rem; }
    #header .contact-info .phone-number {
      font-size: 1rem;
      font-size: 2.2rem;
      font-family: 'roboto';
      font-weight: 700;
      letter-spacing: -0.05em;
      line-height: 1.2em; }
      #header .contact-info .phone-number .icon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #0f2f68 url(../images/icon_phone.svg) no-repeat center center;
        background-size: 10px auto; }
  #header .button-01:link, #header .button-01:visited {
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    font-size: 1.4rem; }
  #header .button-01 .icon {
    position: relative;
    top: 1px;
    display: inline-block;
    margin-right: 8px;
    width: 20px; }

#trunk {
  padding-top: 119px; }

#footer {
  position: relative;
  margin-top: 80px;
  border-top: 1px solid #f4f4f4;
  background-color: #fff; }
  #footer .button-go-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    display: block;
    width: 50px;
    height: 50px;
    background-color: #0f2f68; }
    #footer .button-go-top .arrow {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: 0;
      width: 1px;
      height: 20px;
      line-height: 0;
      font-size: 0;
      background-color: #fff;
      transform: translate(-50%, -50%); }
      #footer .button-go-top .arrow::before {
        display: block;
        position: absolute;
        left: -6px;
        top: 1px;
        height: 1px;
        width: 8px;
        background-color: #fff;
        content: "";
        transform: rotate(-45deg); }
      #footer .button-go-top .arrow::after {
        display: block;
        position: absolute;
        right: -6px;
        top: 1px;
        height: 1px;
        width: 8px;
        background-color: #fff;
        content: "";
        transform: rotate(45deg); }
  #footer .top-row {
    font-size: 1.3rem;
    letter-spacing: 0.1em; }
    #footer .top-row #f-nav {
      float: right;
      width: calc(100% - 413px);
      line-height: 1.6em; }
      #footer .top-row #f-nav .col {
        width: 33.33%; }
        #footer .top-row #f-nav .col > ul > li {
          margin-bottom: 1em; }
          #footer .top-row #f-nav .col > ul > li a {
            font-weight: 500; }
        #footer .top-row #f-nav .col strong {
          font-weight: 500; }
  #footer .info {
    float: left;
    width: 413px; }
    #footer .info .logo img {
      width: 260px; }
    #footer .info p {
      margin-top: 15px;
      vertical-align: bottom;
      font-size: 1.2rem; }
  #footer .bottom-row {
    letter-spacing: 0.1em; }
    #footer .bottom-row .container {
      padding-top: 30px;
      padding-bottom: 30px; }
    #footer .bottom-row nav {
      float: right;
      width: calc(100% - 413px); }
      #footer .bottom-row nav ul li {
        float: left;
        margin-right: 20px;
        font-size: 1.1rem;
        line-height: 1.6em; }
    #footer .bottom-row .copyright {
      float: left;
      font-size: 1rem; }

.contact-section {
  color: #0f2f68;
  text-align: center; }
  .contact-section .container {
    padding-top: 50px;
    padding-bottom: 50px; }
  .contact-section .col:last-of-type {
    padding-left: 60px;
    border-left: 1px solid #e2e2e2; }
  .contact-section h2 {
    margin-bottom: 40px;
    color: #0f2f68;
    font-size: 3.2rem; }
  .contact-section .text-01 {
    font-size: 2.4rem; }
  .contact-section .phone-number {
    font-size: 6.2rem;
    font-family: 'roboto';
    font-weight: 700;
    line-height: 1.2em; }
    .contact-section .phone-number .icon {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -5px;
      margin-right: 15px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background: #0f2f68 url(../images/icon_phone.svg) no-repeat center center;
      background-size: 30px auto; }
  .contact-section .text-02 {
    font-weight: 500; }
  .contact-section .button-01:link, .contact-section .button-01:visited {
    padding: 50px 0 54px 0;
    font-size: 2.6rem; }
  .contact-section .button-01 .icon {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 10px;
    width: 35px; }

/**
*
*	home
*
**/
.home-page .button-wrapper {
  text-align: right; }

.home-page .hero-section {
  overflow: hidden;
  position: relative; }
  .home-page .hero-section .slider {
    overflow: hidden;
    background: #fff;
    opacity: 0.99; }
  .home-page .hero-section .image {
    transform: scale(1);
    transition: all 7000ms linear;
    padding-top: 48.75%;
    background-size: cover;
    background-repeat: none; }
  .home-page .hero-section .swiper-slide-prev .image, .home-page .hero-section .swiper-slide-duplicate-prev .image, .home-page .hero-section .swiper-slide-active .image, .home-page .hero-section .swiper-slide-duplicate-active .image {
    transform: scale(1.1); }
  .home-page .hero-section .slide img {
    display: none;
    width: 100%; }
  .home-page .hero-section .theme {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    z-index: 100;
    color: #fff;
    transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    text-align: left; }
    .home-page .hero-section .theme .copy {
      display: inline-block;
      padding: 20px;
      /* text-shadow: 1px 1px 10px rgba(255,255,255,0.2), 
        -1px -1px 10px rgba(255,255,255,0.2), 
        1px -1px 10px rgba(255,255,255,0.2), 
        -1px 1px 10px rgba(255,255,255,0.2), 
        1px 1px 10px rgba(255,255,255,0.2); */ }
    .home-page .hero-section .theme h1 {
      font-size: 3.6rem;
      line-height: 1.4em;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #fff; }
    .home-page .hero-section .theme p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.8em; }

.home-page .news-section {
  position: relative;
  z-index: 100;
  min-height: 0; }
  .home-page .news-section ul {
    margin-bottom: 40px; }

.home-page .service-section .container {
  padding-bottom: 0; }

.home-page .service-section .cols {
  margin-right: -20px;
  margin-left: -20px; }

.home-page .service-section .col {
  margin-bottom: 40px;
  padding: 0 20px;
  width: 33.33%; }
  .home-page .service-section .col a {
    display: block;
    height: 100%; }
    .home-page .service-section .col a:hover, .home-page .service-section .col a:active {
      opacity: 1;
      -webkit-box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.42);
      -moz-box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.42);
      box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.42); }
      .home-page .service-section .col a:hover .image img, .home-page .service-section .col a:active .image img {
        transform: scale(1.03); }
  .home-page .service-section .col .image {
    overflow: hidden; }
    .home-page .service-section .col .image img {
      transition: all 800ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .home-page .service-section .col figcaption {
    padding: 20px; }
  .home-page .service-section .col .service-name {
    margin-bottom: 0.5em;
    font-size: 1.6rem;
    font-weight: 500; }
  .home-page .service-section .col .service-description {
    color: #333; }

.home-page .service-section .search-box-col {
  width: 66.66%; }
  .home-page .service-section .search-box-col .search-box {
    padding: 30px 30px 20px 30px;
    color: #0f2f68;
    background-color: #eaeff6; }

.home-page .service-section .search-box .box-header {
  padding-left: 50px;
  background: url(../images/icon_search.svg) no-repeat left 5px;
  background-size: 40px auto; }

.home-page .service-section .search-box .box-title {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2em; }

.home-page .service-section .search-box .box-subtitle {
  font-weight: 700; }

.home-page .service-section .search-box .box-contents {
  margin-top: 15px;
  padding-top: 24px;
  border-top: 1px solid #c9d4e2; }

.home-page .service-section .search-box ul {
  margin-left: -5px;
  margin-right: -5px;
  font-size: 0; }
  .home-page .service-section .search-box ul li {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0 5px;
    font-size: 1.4rem; }
  .home-page .service-section .search-box ul a {
    display: inline-block;
    padding: 10px 15px;
    color: #fff;
    border-radius: 6px;
    background-color: #0f2f68; }

.home-page .news-section h2 {
  padding-top: 15px;
  color: #333;
  font-size: 2rem;
  line-height: 1.5em;
  border-top: 1px solid #efefef; }
  .home-page .news-section h2::before {
    display: inline-block;
    position: absolute;
    left: 0;
    top: -1px;
    width: 150px;
    height: 1px;
    background-color: #0f2f68;
    content: ""; }

.home-page .news-section ul li {
  border-bottom: 1px solid #efefef; }

.home-page .news-section ul a {
  display: flex;
  padding: 20px 0; }
  .home-page .news-section ul a .date {
    width: 120px; }

.sub-page .page-header {
  position: relative;
  color: #fff;
  background-size: cover;
  background-position: center center; }
  .sub-page .page-header::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, black 0%, black 20%, rgba(0, 0, 0, 0.7) 44%, rgba(0, 0, 0, 0.2) 100%);
    content: ""; }
  .sub-page .page-header .container {
    position: relative;
    z-index: 10;
    padding-top: 80px;
    padding-bottom: 80px; }
  .sub-page .page-header .page-title {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.4em; }
  .sub-page .page-header .page-sub-title {
    font-size: 1.8rem; }

.sub-page .contents-wrapper {
  display: flex;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 1200px; }
  .sub-page .contents-wrapper.no-sidebar {
    display: block; }
    .sub-page .contents-wrapper.no-sidebar.narrow-contents .main-contents {
      margin: 0 auto;
      width: 70%; }
    .sub-page .contents-wrapper.no-sidebar .main-contents {
      margin: 0 auto;
      padding-right: 0;
      width: auto; }

.sub-page .main-contents {
  padding-right: 7.5%;
  width: 77%; }

.sub-page .sidebar {
  width: 23%; }

.sub-page .head {
  margin-bottom: 32px;
  font-size: 3.2rem;
  line-height: 1.4em; }
  .sub-page .head::after {
    display: block;
    margin-top: 8px;
    height: 5px;
    background: linear-gradient(to right, #0038d0 0%, #3d70fc 100%);
    content: ""; }

.sub-page .subhead {
  position: relative;
  margin-top: 40px;
  color: #0f2f68;
  font-size: 1.8rem;
  line-height: 1.6em; }
  .sub-page .subhead::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 3px;
    background: linear-gradient(to right, #0038d0 0%, #3d70fc 100%);
    content: ""; }
  .sub-page .subhead::after {
    display: block;
    height: 3px;
    margin-top: 8px;
    background-color: #e5e5e5;
    content: ""; }

.sub-page .lead {
  margin-bottom: 40px;
  font-size: 2.4rem;
  line-height: 1.8em;
  text-align: left; }

.local-nav {
  margin-bottom: 56px;
  padding: 10px 16px 13px 16px;
  border: 1px solid #d4dff0;
  border-radius: 6px;
  background-color: #eff4fc; }
  .local-nav ul {
    line-height: 1.4em;
    display: flex;
    flex-wrap: wrap; }
  .local-nav li {
    margin-top: 5px;
    margin-right: 12px;
    margin-bottom: 5px;
    padding-right: 12px;
    text-align: center;
    border-right: 1px solid #d4dff0; }
    .local-nav li:last-of-type {
      border: none; }
  .local-nav a {
    position: relative; }
    .local-nav a::before {
      display: block;
      position: absolute;
      left: 8px;
      top: 50%;
      width: 10px;
      height: 16px;
      background: url(../images/icon_arrow_01.svg) no-repeat center center;
      content: "";
      transform: translate(0, -40%) rotate(90deg); }
    .local-nav a:link, .local-nav a:visited {
      padding: 0 8px 0 28px;
      color: #333;
      border-radius: 4px; }
    .local-nav a:hover, .local-nav a:active {
      opacity: 1;
      background-color: #d4dff0; }

.sidebar dl {
  position: relative; }
  .sidebar dl dt {
    padding: 20px 16px 22px 16px;
    color: #fff;
    font-size: 1.8rem;
    background-color: #0f2f68; }
    .sidebar dl dt::before {
      display: block;
      height: 4px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #0038d0;
      /* Old browsers */
      background: linear-gradient(to right, #0038d0 0%, #3d70fc 100%);
      content: ""; }

.sidebar ul {
  border: 1px solid #d4dff0;
  border-top: none;
  border-bottom: none; }
  .sidebar ul li {
    border-bottom: 1px solid #d4dff0; }
    .sidebar ul li.current a:link, .sidebar ul li.current a:visited {
      color: #4374fc;
      background-color: #d4dff0; }
    .sidebar ul li a {
      display: block;
      padding: 20px 24px 20px 16px;
      background: url(../images/icon_arrow_01.svg) no-repeat right 16px center; }
      .sidebar ul li a:link, .sidebar ul li a:visited {
        background-color: #eff4fc; }
      .sidebar ul li a:hover, .sidebar ul li a:active {
        color: #4374fc;
        background-color: #d4dff0;
        opacity: 1; }

.services-page .service {
  margin-bottom: 80px; }
  .services-page .service .description {
    line-height: 1.8em; }
  .services-page .service .dl-01 {
    margin-top: 16px;
    line-height: 1.5em; }
    .services-page .service .dl-01 dt {
      margin-bottom: 4px;
      color: #0038d0;
      font-size: 18px;
      font-weight: 500; }
      .services-page .service .dl-01 dt::before {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: 6px;
        width: 3px;
        height: 20px;
        background: linear-gradient(to bottom, #0038d0 0%, #3d70fc 100%);
        content: ""; }
    .services-page .service .dl-01 dd {
      margin-bottom: 24px;
      line-height: 1.5em; }

.services-page figure figcaption {
  margin-top: 8px;
  font-size: 1.4rem;
  line-height: 1.5em;
  text-align: right; }

.services-page .steps {
  display: flex;
  margin-top: 48px;
  margin-right: -16px;
  margin-left: -16px; }
  .services-page .steps .step {
    position: relative;
    padding: 0 16px;
    width: 20%;
    text-align: center; }
    .services-page .steps .step::after {
      display: block;
      position: absolute;
      right: -10px;
      top: 50%;
      width: 20px;
      height: 16px;
      background: url(../images/icon_arrow_03.svg) no-repeat center center;
      transform: translate(0, -50%);
      content: ""; }
    .services-page .steps .step:last-of-type::after {
      display: none; }
    .services-page .steps .step .inner {
      position: relative;
      padding-top: 40px;
      padding-bottom: 24px;
      height: 100%;
      background-color: #f4f7fb;
      border: 2px solid #0f2f68;
      border-radius: 6px; }
    .services-page .steps .step .step-label {
      position: absolute;
      left: 50%;
      top: 0;
      padding: 5px 0 8px 0;
      width: 100%;
      max-width: 110px;
      color: #fff;
      font-size: 1.6rem;
      background-color: #0f2f68;
      border-radius: 100px;
      transform: translate(-50%, -50%); }
    .services-page .steps .step .step-icon {
      margin-bottom: 16px; }
    .services-page .steps .step .step-name {
      color: #0f2f68;
      font-size: 2.1rem;
      font-weight: 500; }
    .services-page .steps .step .step-period {
      color: #0f2f68;
      font-size: 1.4rem; }

.services-page.communication-equipment-page .service .cols {
  margin-top: 40px; }

.services-page.electrical-equipment-page .service .cols {
  margin-top: 40px; }

.services-page.earthquake-reinforcement-page .bases .cols {
  margin-top: 40px;
  align-items: center; }
  .services-page.earthquake-reinforcement-page .bases .cols .col {
    width: 50%;
    text-align: center; }

.services-page.earthquake-reinforcement-page .stabilizer .cols {
  margin-top: 40px; }

.services-page.earthquake-reinforcement-page .stabilizer .dl-01 {
  margin-top: 0; }

.services-page.security-air-conditioning-page .service .cols {
  margin-top: 40px; }

.services-page.security-air-conditioning-page .service.access .cols .col:first-of-type {
  width: 60%; }

.services-page.security-air-conditioning-page .service.access .cols .col:last-of-type {
  width: 40%; }

.services-page.office-support-page .service.interior .cols {
  margin-top: 40px;
  margin-bottom: 40px; }

.services-page.office-support-page .service.restoration .cols .col:first-of-type {
  width: 64%; }

.services-page.office-support-page .service.restoration .cols .col:last-of-type {
  width: 36%; }

.company-page.about-page .section {
  margin-bottom: 80px; }

.company-page.about-page .profile-section .dl-table dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .company-page.about-page .profile-section .dl-table dl dt {
    margin-bottom: 0.5em;
    width: calc(100% - 80px); }
  .company-page.about-page .profile-section .dl-table dl dd {
    margin-bottom: 0.5em;
    width: 80px;
    text-align: right; }

.company-page .message-section .cols .col:first-of-type {
  width: 36%; }

.company-page .message-section .cols .col:last-of-type {
  width: 64%; }

.company-page .message-section p {
  margin-bottom: 1.5em;
  line-height: 1.8em; }

.company-page .message-section .signature {
  margin-top: 40px;
  margin-bottom: 80px;
  font-size: 1.4rem;
  text-align: right; }

.company-page.equipment-page .box-list-01 li {
  border: 1px solid #f4f4f4; }

.company-page.philosophy-page .section {
  margin-bottom: 80px; }

.company-page.philosophy-page .philosophy-section .lead {
  color: #0f2f68;
  font-family: 'Noto Serif JP', serif;
  font-size: 3.2rem;
  font-style: italic;
  line-height: 2em;
  text-align: center; }

.company-page.philosophy-page .guidance-section ul {
  margin-left: 0.7em;
  font-size: 1.8rem;
  line-height: 1.6em; }
  .company-page.philosophy-page .guidance-section ul li {
    margin-bottom: 1em;
    text-indent: -0.7em; }
    .company-page.philosophy-page .guidance-section ul li::before {
      display: inline-block;
      position: relative;
      top: 4px;
      margin-right: 8px;
      width: 4px;
      height: 22px;
      background: linear-gradient(to bottom, #0038d0 0%, #3d70fc 100%);
      content: ""; }

.company-page.philosophy-page .strength-section p {
  font-size: 1.8rem; }

.company-page.access-page #map-container {
  margin: 40px 0;
  height: 500px;
  overflow: hidden;
  position: relative; }

.company-page.access-page #map-container iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%; }

.company-page.access-page .access-section {
  margin-bottom: 80px; }
  .company-page.access-page .access-section .label-01 {
    margin-bottom: 0.5em;
    color: #0f2f68;
    font-weight: 600; }
    .company-page.access-page .access-section .label-01::before {
      display: inline-block;
      position: relative;
      top: 4px;
      margin-right: 8px;
      width: 4px;
      height: 20px;
      background: linear-gradient(to bottom, #0038d0 0%, #3d70fc 100%);
      content: ""; }

.recruit-page .lead {
  font-size: 1.8rem; }

.recruit-page .subhead {
  font-size: 2.4rem; }

.recruit-page .dl-table {
  margin: 32px auto 0 auto; }
  .recruit-page .dl-table dt {
    padding: 24px;
    width: 240px; }
  .recruit-page .dl-table dd {
    padding: 24px;
    width: calc(100% - 240px); }

.privacy-page .lead {
  font-size: 1.8rem; }

.privacy-page .list-01 li {
  margin-bottom: 1.5em; }

.sitemap-page .main-contents .cols {
  justify-content: space-between; }
  .sitemap-page .main-contents .cols .col {
    width: 33.33%; }

.sitemap-page .main-contents .category::after {
  display: block;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 16px;
  height: 1px;
  background-color: #ddd;
  content: ""; }

.sitemap-page .main-contents .category:last-of-type::after {
  display: none; }

.sitemap-page .main-contents .category a {
  display: inline-block; }

.sitemap-page .main-contents .category li {
  margin-bottom: 16px; }

.sitemap-page .main-contents .category > li > a {
  color: #0038d0;
  font-size: 1.8rem; }

.sitemap-page .main-contents .category > li ul {
  margin-top: 24px; }
  .sitemap-page .main-contents .category > li ul li::before {
    display: inline-block;
    margin-right: 8px;
    width: 5px;
    height: 10px;
    background: url(../images/icon_arrow_01.svg) no-repeat center center;
    background-size: contain;
    content: ""; }

.cases-page .main-contents .boxes {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }
  .cases-page .main-contents .boxes .box {
    position: relative;
    margin-bottom: 40px;
    padding: 0 20px;
    width: 33.33%; }
    .cases-page .main-contents .boxes .box .inner {
      position: relative;
      padding: 24px;
      height: 100%;
      background-color: #f8f8f8;
      border-radius: 8px; }
    .cases-page .main-contents .boxes .box .project-name {
      margin-top: 0;
      margin-bottom: 14px;
      color: #0f2f68;
      font-size: 2rem;
      line-height: 1.4em; }
      .cases-page .main-contents .boxes .box .project-name::after {
        margin-top: 12px; }
    .cases-page .main-contents .boxes .box .project-note {
      margin-top: 20px;
      font-size: 1.3rem; }

.contact-page .main-contents .lead {
  font-size: 1.8rem; }

.contact-page .main-contents .subhead {
  font-size: 2.4rem; }

.contact-page .main-contents .phone-number {
  margin: 32px 0 0 0;
  font-size: 1.3rem; }
  .contact-page .main-contents .phone-number strong {
    margin-right: 8px;
    font-family: 'roboto';
    font-size: 2.4rem;
    font-weight: 600; }

.contact-page .main-contents .dl-table {
  margin: 32px auto 40px auto; }
  .contact-page .main-contents .dl-table dt {
    padding: 24px;
    width: 240px; }
  .contact-page .main-contents .dl-table dd {
    padding: 24px;
    width: calc(100% - 240px); }

.contact-page .main-contents input[type="text"],
.contact-page .main-contents textarea {
  padding: 8px 16px;
  max-width: 400px;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5em;
  border: 1px solid #ddd;
  border-radius: 8px; }
  .contact-page .main-contents input[type="text"]:focus,
  .contact-page .main-contents textarea:focus {
    border-color: #adc0f3;
    outline: none; }

.contact-page .main-contents textarea {
  max-width: none; }

.contact-page .main-contents .required {
  color: #db0d0d;
  font-size: 1.8rem; }

.contact-page .main-contents .contact-footer {
  text-align: center; }

.contact-page .main-contents .agreement {
  margin: 40px auto;
  padding: 24px;
  max-width: 400px;
  background-color: #fcefef;
  border-radius: 8px; }

.contact-page .main-contents .actions {
  text-align: center; }
  .contact-page .main-contents .actions .button-01 {
    margin: 0 auto;
    padding: 16px 40px;
    min-width: 240px;
    font-size: 1.6rem; }

.news-list {
  margin-bottom: 40px;
  border-top: 1px solid #e2e2e2; }
  .news-list li {
    border-bottom: 1px solid #e2e2e2; }
  .news-list a:link, .news-list a:visited {
    display: flex;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    color: #333; }
  .news-list a:hover, .news-list a:active {
    opacity: 1;
    background-color: #eff4fc; }
  .news-list .date {
    padding: 30px 0 30px 0;
    width: 120px;
    flex: 0 0 auto;
    -ms-flex: 0 0 auto; }
  .news-list .categories {
    flex: 0 0 auto;
    padding: 30px 20px;
    text-align: left;
    white-space: nowrap;
    font-size: 0;
    word-wrap: none; }
    .news-list .categories span {
      display: inline-block;
      margin: 0 2px;
      padding: 3px 16px;
      width: 120px;
      color: #fff;
      font-size: 1.2rem;
      line-height: 1.6em;
      text-align: center;
      background-color: #00a27a; }
      .news-list .categories span.important {
        width: 60px;
        background-color: #d63636; }
  .news-list .title {
    flex: 1 1 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 1.6rem; }

.news-page .news-list {
  margin-top: 40px; }

.news-page .post h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.5em; }

.news-page .post .post-meta p {
  display: inline-block; }

.news-page .post .post-body {
  margin-top: 20px;
  line-height: 1.8em;
  letter-spacing: 0.05em; }
  .news-page .post .post-body p {
    margin-bottom: 1.5em; }
