@charset "utf-8";

$ja: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
$darkBlue: #0f2f68;
$blue: #0038d0;
$lightBlue: #3d70fc;
$gold: #b2881f;
$DarkerGold: #8e672c;
$red: #db0d0d;
$linkColor: #0600ff;
$blueGradient: linear-gradient(to right,  $blue 0%, $lightBlue 100%);
$blueVerticalGradient: linear-gradient(to bottom,  $blue 0%,#3d70fc 100%);
$outline: 2px 2px 0 #FFF, -2px -2px 0 #FFF,
              -2px 2px 0 #FFF, 2px -2px 0 #FFF,
              0px 2px 0 #FFF,  0 -2px 0 #FFF,
              -2px 0 0 #FFF, 2px 0 0 #FFF;
$outline2: 1px 1px 0 #FFF, -1px -1px 0 #FFF,
              -1px 1px 0 #FFF, 1px -1px 0 #FFF,
              0px 1px 0 #FFF,  0 -1px 0 #FFF,
              -1px 0 0 #FFF, 1px 0 0 #FFF;	
$transition01: all 500ms cubic-bezier(.215, .61, .355, 1);				



@mixin for-tablet {
  @media (max-width: 1053px) { @content; }
}

@mixin for-mid-tablet {
  @media (max-width: 900px) { @content; }
}

@mixin for-phone {
  @media (max-width: 600px) { @content; }
}

@mixin for-mid-phone {
  @media (max-width: 400px) { @content; }
}

@mixin for-sm-phone {
  @media (max-width: 320px) { @content; }
}


@keyframes fadeIn {
  0% {
		display: none;
		opacity: 0;
  }
  1% {
		display: block;
		opacity: 0;
  }	
  100% {
		display: block;
		opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
		display: block;
		opacity: 1;
  }
  99% {
		display: block;
		opacity: 0;
  }		
  100% {
		display: none;
		opacity: 0;
  }
}

@keyframes drawLine {
  0%{stroke-dashoffset:3000;fill-opacity: 0;}
  30% {fill-opacity: 0;}
  35% {fill-opacity: 1;}
  100%{stroke-dashoffset:0;fill-opacity: 1;}
}

@keyframes curtain {
  0% {
    left: 0;
		width: 0;
  }
  50% {
    left: 0;
		width: 100%;
  }	
  51% {
    left: auto;
    right: 0;
		width: 100%;
  }	  
  100% {
    left: auto;
    right: 0;
		width: 0;
  }
}




/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
	display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000; 
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}



/**
*
*	common properties
*
**/



html {
	font-size: 62.5%;
}

body {
  color: #333;
	font-family: $ja;
	font-size: 16px;
	font-size: 1.6rem;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

img, a {
  border: none;
  outline: none;
}

label {
  cursor: pointer;
}

p {
  line-height: 1.6em;
}

main {
  display: block;
}

.cols::after, .clearfix::after {
  content: "";
  display: block;
  clear: both;
}

ol, ul {
  list-style: none;
}

a {
  &:link, &:visited {
    color: $darkBlue;
    text-decoration: none;
    transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:hover, &:active {
    opacity: 0.7;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ins {
  display: inline-block;
  vertical-align: bottom;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.no-scroll {
  overflow-y: hidden;
}

.modal-hidden-contents {
  display: none;
}

#modal-base-layer {
  background: #000;
  color: #000;
  display: none;
  position: fixed;
  _position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100px;
  z-index: 9900;
  text-align: center;
}

#modal-contents-layer {
  display: none;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9901;

  #modal-iscroll-container-inner {
    overflow: hidden;
  }
}

button {
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    opacity: 0.7;
  }
}

.tac {
  text-align: center;
}

.fcr {
  color: #ff0000;
}

.fwb {
  font-weight: bold;
}

.hidden, .sp-elem, .sp-il-elem {
  display: none;
}

area:focus {
  outline: none;
  border: none;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: bottom;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
  .col {
    padding: 0 20px;
    width: 50%;
  }
  &.reverse {
    flex-flow: row-reverse;
  }
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 70px 20px;
  max-width: 1240px;
}

.breadcrumbs {
  background-color: #f5f5f5;
  .container {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 7px;
      color: #333;
      font-size: 1.3rem;
      &::after {
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 1px;
        margin-left: 7px;
        width: 8px;
        height: 12px;
        background: url(../images/icon_arrow_02.svg) no-repeat left top;
        content: "";
      }
      &:first-of-type {
        height: 13px;
        a {
          overflow: hidden;
          position: relative;
          top: -2px;
          width: 16px;
          height: 16px;          
          text-indent: -9999px;
          background: url(../images/icon_home_01.svg) no-repeat left top;
          background-size: contain;
        }
      }
      &:last-of-type::after {
        display: none;
      }
    }
    a {
      display: inline-block;
      vertical-align: top;
      &:link, &:visited {
        color: #333;
      }
    }
  }
}

h2 {
  position: relative;
  margin-bottom: 35px;
  color: #333;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.button-wrapper {
  text-align: center;
}

.pagination {
	text-align: center;
  .group {
    display: inline-block;
    font-size: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.1),
              0 1px 14px 2px rgba(0, 0, 0, 0.1),
              0 1px 5px -3px rgba(0, 0, 0, 0.1);
    *:first-of-type {
      border-radius: 4px 0 0 4px;	
    }
  }  
  .current,
  a:link,
  a:visited {
    display: inline-block;
    vertical-align: top;
    padding: 12px 0 12px 0;	
    width: 40px;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: #fff;
    border-right: 1px solid #efefef;
  }
  .current {
    color: $blue;
  }
  a {
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }    
    &:hover, &:active {
    opacity: 1;
    background-color: #eff4fc;     
    }
  }
  img {
    position: relative;
    top: -1px;   
    width: auto;
    height: 12px; 
  }
}

.box-list-01 {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  li {
    margin: 0 10px 20px 10px;
    width: calc(33.33% - 20px);
    background-color: #f4f4f4;
    p {
      padding: 16px;
      font-size: 1.4rem;   
      font-weight: 500;         
    }
  }
}

.dl-table {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5em;
  border-bottom: 1px solid #ddd;
  > dt {
    padding: 16px;
    width: 160px;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #f4f4f4;
  }
  > dd {
    padding: 16px;
    width: calc(100% - 160px);
    border: 1px solid #ddd;
    border-left: none;
    border-bottom: none;
  }
}

.list-01 {
  margin-top: 16px;
  margin-left: 0.8em;
  li {
    margin-bottom: 0.5em;
    line-height: 1.5em;
    text-indent: -0.8em;
    &::before {
      position: relative;
      top: -3px;
      margin-right: 0.3em;
      color: $lightBlue;
      font-size: 10px;          
      content: "●";
    }
    strong {
      color: #fff;
      background-color: $lightBlue;
    }
  }
}

.arrow-button-01 {
  display: inline-block;
  color: $darkBlue;
  &:link, &:visited {
    display: inline-block;
    color: $darkBlue;
  }
  .arrow {
    display: inline-block;
    position: relative;
    top: -4px;
    margin-left: 5px;
    width: 20px;
    height: 1px;
    line-height: 0;
    font-size: 0;
    background-color: $darkBlue;
    &::before {
      display: block;
      position: absolute;
      right: 0;
      top: -3px;
      height: 1px;
      width: 8px;
      background-color: $darkBlue;
      content: "";
      transform: rotate(45deg);
    }
    &::after {
      display: block;
      position: absolute;
      right: 0;
      top: 3px;
      height: 1px;
      width: 8px;
      background-color: $darkBlue;
      content: "";
      transform: rotate(-45deg);
    }
  }
  &.white {
    color: #fff;
    .arrow {
      background-color: #fff;

      &::before, &::after {
        background-color: #fff;
      }
    }
  }
  &.reverse {
    display: inline-block;
    padding-right: 5px;
    padding-left: 15px;
    text-align: right;
    &.hidden {
      display: none;
    }
    .arrow {
      position: relative;
      left: -15px;
      &::before {
        left: 0;
        top: -3px;
        transform: rotate(-45deg);
      }
      &::after {
        left: 0;
        top: 3px;
        transform: rotate(45deg);
      }
    }
  }
}

.arrow-button-02 {
  display: inline-block;
  padding-right: 15px;
  padding-left: 5px;

  .arrow {
    display: block;
    position: relative;
    margin: 3px auto 0 auto;
    width: 1px;
    height: 15px;
    background-color: transparent;
    &::before {
      display: block;
      position: absolute;
      right: -1px;
      bottom: 1px;
      height: 2px;
      width: 8px;
      background-color: #000;
      content: "";
      transform: rotate(45deg);
    }
    &::after {
      display: block;
      position: absolute;
      left: -1px;
      bottom: 1px;
      height: 2px;
      width: 8px;
      background-color: #000;
      content: "";
      transform: rotate(-45deg);
    }
  }
}

.arrow-button-03 {
  &:link, &:visited {
    color: #00a27a;
  }
  &::after {
    display: inline-block;
    margin-left: 5px;
    width: 8px;
    height: 10px;
    background: url(../img/icon_arrow_02.svg) no-repeat left top;
    content: "";
    transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}


.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 7px;
}

.swiper-pagination-bullet {
  width: 40px;
  height: 2px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.8;
}

.swiper-pagination-bullet-active {
  background-color: $darkBlue;
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 30px;
}

.bg-gray {
  background-color: #f8f8f8;
}

.button-01 {
  display: block;
  color: #fff;
  background-color: #47b510;
  padding: 20px 0 24px 0;
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  &:link, &:visited {
    display: block;
    color: #fff;
    background-color: #47b510;
    padding: 20px 0 24px 0;
    font-family: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover, &:active {
    opacity: 1;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 3px 5px -3px rgba(0, 0, 0, 0.4);
  }
  &:focus {
    outline: none;
  }
}

/**
*
*	modules
*
**/

#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  border-top: 8px solid $darkBlue;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  .container {
    height: 114px;
    padding: 0 20px;
  }
  a {
    &:link, &:visited {
      color: #333;
    }
  }
  h1 {
    position: absolute;
    left: 20px;
    top: 5px;
    font-size: 1rem;
    font-weight: normal;
  }
  .logo {
    display: inline-block;
    margin-top: 34px;
  }
  #g-nav {
    float: right;
    margin-top: 64px;
    margin-right: 170px;
    letter-spacing: 0.05em;
    ul {
      font-size: 0;
    }
    li {
      display: inline-block;
      vertical-align: top;
      padding-bottom: 16px;
      font-size: 1.4rem;
      text-align: center;
      &::before {
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 0;
        width: 1px;
        height: 35px;
        background-color: #ddd;
        content: "";
      }
    }
    .has-child {
      > a {
        &::after {
          display: inline-block;
          position: relative;
          top: -2px;
          margin-left: 5px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: #333 transparent transparent transparent;
          content: "";
        }
      }
    }
    li {
      a {
        display: inline-block;
        vertical-align: top;
        padding: 8px 20px 8px 20px;
        line-height: 1.4em;
      }
      span {
        color: #00a27a;
        font-size: 10px;
      }
    }
    .child {
      display: none;
      position: absolute;
      left: 0;
      top: 114px;
      width: 500%;
      padding: 40px 50% 10px 50%;
      margin: 0 -50%;
      text-align: left;
      background-color: #fff;
      -webkit-box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
      .inner {
        max-width: 1200px;
      }
      .label {
        float: left;
        margin-bottom: 50px;
        padding-left: 20px;
        color: $darkBlue;
        font-size: 1.8rem;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        width: 310px;
        span {
          display: block;
          color: #333;
          font-size: 1rem;
        }
      }
      ul {
        float: left;
        position: relative;
        padding-right: 20px;
        width: calc(100% - 310px);
        &::before {
          display: block;
          position: absolute;
          left: -40px;
          top: 0;
          width: 1px;
          height: 24px;
          background-color: #ccc;
          content: "";
        }
        li {
          display: block;
          float: left;
          margin-bottom: 30px;
          padding-bottom: 0;
          width: 33.33%;
          text-align: left;
          &::before, &::after {
            display: none;
          }

          a {
            display: block;
            padding: 0;
          }
        }
      }      
    }
    .sp-elem {
      display: none;
    }    
  }
  .sub-nav {
    position: absolute;
    top: 0;
    right: 190px;
    ul {
      font-size: 0;
      li {
        display: inline-block;
        vertical-align: top;
        font-size: 1.2rem;
        line-height: 34px;
        img {
          position: relative;
          top: -1px;
          margin-right: 5px;
          vertical-align: middle;
          width: 15px;
        }
        a {
          padding: 0 10px;
        }
        ul {
          li {
            display: list-item;
          }
        }
      }
    }
  }

  .contact-info {
    position: absolute;
    right: 20px;
    top: 5px;
    width: 170px;
    text-align: center;
    .text-01 {
      font-size: 1.1rem;
    }
    .phone-number {
      font-size: 1rem;
      font-size: 2.2rem;
      font-family: 'roboto';
      font-weight: 700;
      letter-spacing: -0.05em;
      line-height: 1.2em;
      .icon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: $darkBlue url(../images/icon_phone.svg) no-repeat center center;
        background-size: 10px auto;
      }
    }
  }
  .button-01 {
    &:link, &:visited {
      margin-top: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #fff;
      font-size: 1.4rem;
    }
    .icon {
      position: relative;
      top: 1px;
      display: inline-block;
      margin-right: 8px;
      width: 20px;
    }
  }  
}

#trunk {
  padding-top: 119px;
}

#footer {
  position: relative;
  margin-top: 80px;
  border-top: 1px solid #f4f4f4;
  background-color: #fff;
  .button-go-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    display: block;
    width: 50px;
    height: 50px;
    background-color: $darkBlue;
    .arrow {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: 0;
      width: 1px;
      height: 20px;
      line-height: 0;
      font-size: 0;
      background-color: #fff;
      transform: translate(-50%, -50%);
      &::before {
        display: block;
        position: absolute;
        left: -6px;
        top: 1px;
        height: 1px;
        width: 8px;
        background-color: #fff;
        content: "";
        transform: rotate(-45deg);
      }
      &::after {
        display: block;
        position: absolute;
        right: -6px;
        top: 1px;
        height: 1px;
        width: 8px;
        background-color: #fff;
        content: "";
        transform: rotate(45deg);
      }
    }
  }
  .top-row {
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    #f-nav {
      float: right;
      width: calc(100% - 413px);
      line-height: 1.6em;
      .col {
        width: 33.33%;
        > ul {
          > li {
            margin-bottom: 1em;
            a {
              font-weight: 500;
            }                    
          }
        }
        strong {
          font-weight: 500;
        }
      }
    }
  }
  .info {
    float: left;
    width: 413px;
    .logo {
      img {
        width: 260px;
      }
    }
    p {
      margin-top: 15px;
      vertical-align: bottom;
      font-size: 1.2rem;
    }
  }
  .bottom-row {
    letter-spacing: 0.1em;
    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    nav {
      float: right;
      width: calc(100% - 413px);
      ul {
        li {
          float: left;
          margin-right: 20px;
          font-size: 1.1rem;
          line-height: 1.6em;
        }        
      }
    }
    .copyright {
      float: left;
      font-size: 1rem;
    }
  }
}

.contact-section {
  color: $darkBlue;
  text-align: center;
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .col:last-of-type {
    padding-left: 60px;
    border-left: 1px solid #e2e2e2;
  }
  h2 {
    margin-bottom: 40px;
    color: $darkBlue;
    font-size: 3.2rem;
  }
  .text-01 {
    font-size: 2.4rem;
  }
  .phone-number {
    font-size: 6.2rem;
    font-family: 'roboto';
    font-weight: 700;
    line-height: 1.2em;
    .icon {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -5px;
      margin-right: 15px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background: $darkBlue url(../images/icon_phone.svg) no-repeat center center;
      background-size: 30px auto;
    }
  }
  .text-02 {
    font-weight: 500;
  }
  .button-01 {
    &:link, &:visited {
      padding: 50px 0 54px 0;
      font-size: 2.6rem;
    }
    .icon {
      display: inline-block;
      position: relative;
      top: 1px;
      margin-right: 10px;
      width: 35px;
    }
  }
}


/**
*
*	home
*
**/

.home-page {
  .button-wrapper {
    text-align: right;
  }
  .hero-section {
    overflow: hidden;
    position: relative;  
    .slider {
      overflow: hidden;
      background: #fff;
      opacity: 0.99;
    }
    .image {
      transform: scale(1);
      transition: all 7000ms linear;
      padding-top: 48.75%;
      background-size: cover;
      background-repeat: none;
    }
    .swiper-slide-prev .image, .swiper-slide-duplicate-prev .image, .swiper-slide-active .image, .swiper-slide-duplicate-active .image {
      transform: scale(1.1);
    }      
    .slide img {
      display: none;
      width: 100%;
    }
    .theme {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      z-index: 100;
      color: #fff;
      transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      text-align: left;

      .copy {
        display: inline-block;
        padding: 20px;
        /* text-shadow: 1px 1px 10px rgba(255,255,255,0.2), 
        -1px -1px 10px rgba(255,255,255,0.2), 
        1px -1px 10px rgba(255,255,255,0.2), 
        -1px 1px 10px rgba(255,255,255,0.2), 
        1px 1px 10px rgba(255,255,255,0.2); */
      }
      h1 {
        font-size: 3.6rem;
        line-height: 1.4em;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #fff;
      }
      p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8em;
      }
    }
  }
  .news-section {
    position: relative;
    z-index: 100;
    min-height: 0;
    ul {
      margin-bottom: 40px;
    }
  }
  .service-section {
    .container {
      padding-bottom: 0;
    }
    .cols {
      margin-right: -20px;
      margin-left: -20px;
    }
    .col {
      margin-bottom: 40px;
      padding: 0 20px;
      width: 33.33%;
      a {
        display: block;
        height: 100%;
        &:hover, &:active {
          opacity: 1;
          -webkit-box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.42);
          -moz-box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.42);
          box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.42);
          .image {
            img {
              transform: scale(1.03);
            }
          }
        }
      }
      .image {
        overflow: hidden;
        img {
          transition: all 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
      figcaption {
        padding: 20px;
      }
      .service-name {
        margin-bottom: 0.5em;
        font-size: 1.6rem;
        font-weight: 500;
      }
      .service-description {
        color: #333;
      }
    }
    .search-box-col {
      width: 66.66%;
      .search-box {
        padding: 30px 30px 20px 30px;
        color: $darkBlue;
        background-color: #eaeff6;
      }
    }
    .search-box {
      .box-header {
        padding-left: 50px;
        background: url(../images/icon_search.svg) no-repeat left 5px;
        background-size: 40px auto;
      }
      .box-title {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.2em;
      }
      .box-subtitle {
        font-weight: 700;
      }
      .box-contents {
        margin-top: 15px;
        padding-top: 24px;
        border-top: 1px solid #c9d4e2;
      }
      ul {
        margin-left: -5px;
        margin-right: -5px;
        font-size: 0;
        li {
          display: inline-block;
          margin-bottom: 10px;
          padding: 0 5px;
          font-size: 1.4rem;
        }
        a {
          display: inline-block;
          padding: 10px 15px;
          color: #fff;
          border-radius: 6px;
          background-color: $darkBlue;
        }
      }
    }
  }

  .news-section {
    h2 {
      padding-top: 15px;
      color: #333;
      font-size: 2rem;
      line-height: 1.5em;
      border-top: 1px solid #efefef;
      &::before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: -1px;
        width: 150px;
        height: 1px;
        background-color: $darkBlue;
        content: "";
      }
    }
    ul {
      li {
        border-bottom: 1px solid #efefef;
      }
      a {
        display: flex;
        padding: 20px 0;
        .date {
          width: 120px;
        }
      }
    }
  }
}


.sub-page {
  .page-header {
    position: relative;
    color: #fff;
    background-size: cover;
    background-position: center center;
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 20%,rgba(0,0,0,0.7) 44%,rgba(0,0,0,0.2) 100%);
      content: "";
    }
    .container {
      position: relative;
      z-index: 10;
      padding-top: 80px;
      padding-bottom: 80px;
    }
    .page-title {
      font-size: 4rem;
      font-weight: 400;
      line-height: 1.4em;
    }
    .page-sub-title {
      font-size: 1.8rem;
    }    
  }
  .contents-wrapper {
    display: flex;
    margin: 0 auto;
    padding: 40px 0;
    max-width: 1200px;
    &.no-sidebar {
      display: block;
      &.narrow-contents {
        .main-contents {
          margin: 0 auto;
          width: 70%;
        }
      }
      .main-contents {
        margin: 0 auto;
        padding-right: 0;
        // width: 70%;
        width: auto;
      }
    }
  }
  .main-contents {
    padding-right: 7.5%;
    width: 77%;
  }
  .sidebar {
    width: 23%;
  }
  .head {
    margin-bottom: 32px;
    font-size: 3.2rem;
    line-height: 1.4em;
    &::after {
      display: block;
      margin-top: 8px;
      height: 5px;
      background: $blueGradient;
      content: "";
    }    
  }
  .subhead {
    position: relative;
    margin-top: 40px;
    color: $darkBlue;
    font-size: 1.8rem;
    line-height: 1.6em;
    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 80px;
      height: 3px;
      background: $blueGradient;
      content: "";
    }
    &::after {
      display: block;
      height: 3px;
      margin-top: 8px;
      background-color: #e5e5e5;
      content: "";
    }
  }
}

.sub-page {
  .lead {
    margin-bottom: 40px;
    font-size: 2.4rem;
    line-height: 1.8em;
    text-align: left;
  }
}

.local-nav {
  margin-bottom: 56px;
  padding: 10px 16px 13px 16px;
  border: 1px solid #d4dff0;
  border-radius: 6px;
  background-color: #eff4fc;
  ul {
    line-height: 1.4em;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-top: 5px;
    margin-right: 12px;
    margin-bottom: 5px;
    padding-right: 12px;
    text-align: center;
    border-right: 1px solid #d4dff0;
    // &::after {
    //   display: inline-block;
    //   width: 1px;
    //   height: 30px;
    //   background-color: #d4dff0;
    //   content: "";
    // }
    &:last-of-type {
      border: none;
    }
  }
  a {
    position: relative;
    &::before {      
      display: block;
      position: absolute;
      left: 8px;
      top: 50%;
      width: 10px;
      height: 16px;
      background: url(../images/icon_arrow_01.svg) no-repeat center center;
      content: "";
      transform: translate(0, -40%) rotate(90deg);
    }
    &:link, &:visited {
      padding: 0 8px 0 28px;
      color: #333;
      border-radius: 4px;
    }
    &:hover, &:active {
      opacity: 1;
      background-color: #d4dff0;
    }
  }
}

.sidebar {
  dl {
    position: relative;
    dt {
      padding: 20px 16px 22px 16px;
      color: #fff;
      font-size: 1.8rem;
      background-color: $darkBlue;
      &::before {
        display: block;
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;        
        background: $blue; /* Old browsers */
        background: $blueGradient;
        content: "";
      }
    }
  }
  ul {
    border: 1px solid #d4dff0;
    border-top: none;
    border-bottom: none;
    li {
      border-bottom: 1px solid #d4dff0;
      &.current {        
        a {          
          &:link, &:visited {
            color: #4374fc;
            background-color: #d4dff0;
          }
        }
      }
      a {
        display: block;
        padding: 20px 24px 20px 16px;
        background: url(../images/icon_arrow_01.svg) no-repeat right 16px center;
        &:link, &:visited {
          background-color: #eff4fc;
        }
        &:hover, &:active {
          color: #4374fc;
          background-color: #d4dff0;
          opacity: 1;
        }
      }
    }
  }
}

.services-page {
  .service {
    margin-bottom: 80px;
    .description {
      line-height: 1.8em;
    }    
    .dl-01 {
      margin-top: 16px;
      line-height: 1.5em;
      dt {        
        margin-bottom: 4px;
        color: $blue;
        font-size: 18px;
        font-weight: 500;
        &::before {
          display: inline-block;
          position: relative;
          top: 3px;
          margin-right: 6px;
          width: 3px;
          height: 20px;
          background: $blueVerticalGradient;
          content: "";
        }
      }      
      dd {
        margin-bottom: 24px;
        line-height: 1.5em;
      }      
    }    
  }
  figure {
    figcaption {
      margin-top: 8px;
      font-size: 1.4rem;
      line-height: 1.5em;
      text-align: right;
    }
  }
  .steps {
    display: flex;
    margin-top: 48px;
    margin-right: -16px;
    margin-left: -16px;
    .step {
      position: relative;
      padding: 0 16px;
      width: 20%;
      text-align: center;
      &::after {
        display: block;
        position: absolute;
        right: -10px;
        top: 50%;
        width: 20px;
        height: 16px;
        background: url(../images/icon_arrow_03.svg) no-repeat center center;
        transform: translate(0,-50%);
        content: "";
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }
      .inner {
        position: relative;
        padding-top: 40px;
        padding-bottom: 24px;
        height: 100%;
        background-color: #f4f7fb;
        border: 2px solid $darkBlue;
        border-radius: 6px;
      }
      .step-label {
        position: absolute;
        left: 50%;
        top: 0;
        padding: 5px 0 8px 0;
        width: 100%;
        max-width: 110px;
        color: #fff;
        font-size: 1.6rem;
        background-color: $darkBlue;
        border-radius: 100px;
        transform: translate(-50%, -50%);
      }
      .step-icon {
        margin-bottom: 16px;
      }
      .step-name {
        color: $darkBlue;
        font-size: 2.1rem;
        font-weight: 500;
      }
      .step-period {
        color: $darkBlue;
        font-size: 1.4rem;
      }
    }
  }
  &.communication-equipment-page {
    .service {
      .cols {
        margin-top: 40px;
      }
    }
  }
  &.electrical-equipment-page {
    .service {
      .cols {
        margin-top: 40px;
      }
    }
  }
  &.earthquake-reinforcement-page {
    .bases {      
      .cols {
        margin-top: 40px;
        align-items: center;
        .col {          
          width: 50%;
          text-align: center;
        }
      }
    }
    .stabilizer {
      .cols {
        margin-top: 40px;
      }
      .dl-01 {
        margin-top: 0;
      }
    }
  }
  &.security-air-conditioning-page {
    .service {
      .cols {
        margin-top: 40px;
      }
      &.access {
        .cols {
          .col {
            &:first-of-type {
              width: 60%;
            }            
            &:last-of-type {
              width: 40%;
            }
          }
        }
      }
    }
  }
  &.office-support-page {
    .service {
      &.interior {
        .cols {
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
      &.restoration  {
        .cols {
          .col {
            &:first-of-type {
              width: 64%;
            }
            &:last-of-type {
              width: 36%;
            }            
          }
        }
      }      
    }
  }
}

.company-page {
  &.about-page {
    .section {
      margin-bottom: 80px;
    }
    .profile-section {
      .dl-table {
        dl {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          dt {
            margin-bottom: 0.5em;
            width: calc(100% - 80px);
          }
          dd {
            margin-bottom: 0.5em;
            width: 80px;
            text-align: right;
          }
        }
      }
    }
  }
  .message-section {
    .cols {
      .col {
        &:first-of-type {
          width: 36%;
        }
        &:last-of-type {
          width: 64%;
        }        
      }
    }
    p {
      margin-bottom: 1.5em;
      line-height: 1.8em;
    }
    .signature {
      margin-top: 40px;
      margin-bottom: 80px;
      font-size: 1.4rem;
      text-align: right;
    }
  }
  &.equipment-page {
    .box-list-01 {
      li {
        border: 1px solid #f4f4f4;
      }
    }
  }
  &.philosophy-page {
    .section {
      margin-bottom: 80px;
    }
    .philosophy-section {
      .lead {
        color: $darkBlue;
        font-family: 'Noto Serif JP', serif;
        font-size: 3.2rem;
        font-style: italic;
        line-height: 2em;
        text-align: center;
      }
    }
    .guidance-section {
      ul {
        margin-left: 0.7em;
        font-size: 1.8rem;
        line-height: 1.6em;
        li {
          margin-bottom: 1em;
          text-indent: -0.7em;
          &::before {
            display: inline-block;
            position: relative;
            top: 4px;
            margin-right: 8px;
            width: 4px;
            height: 22px;
            background: $blueVerticalGradient;
            content: "";
          }
        }
      }
    }
    .strength-section {
      p {
        font-size: 1.8rem;
      }
    }
  }
  &.access-page {
    #map-container {
      margin: 40px 0;
      height: 500px;
      overflow: hidden;
      position: relative;
    }
    #map-container iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
    .access-section {
      margin-bottom: 80px;
      .label-01 {
        margin-bottom: 0.5em;
        color: $darkBlue;
        font-weight: 600;
        &::before {
          display: inline-block;
          position: relative;
          top: 4px;
          margin-right: 8px;
          width: 4px;
          height: 20px;
          background: $blueVerticalGradient;
          content: "";
        }        
      }
    }
  }
}


.recruit-page {
  .lead {
    font-size: 1.8rem;
  }
  .subhead {
    font-size: 2.4rem;
  }
  .dl-table {
    margin: 32px auto 0 auto;
    dt {
      padding: 24px;
      width: 240px;
    }
    dd {
      padding: 24px;
      width: calc(100% - 240px);
    }
  }
}

.privacy-page {
  .lead {
    font-size: 1.8rem;
  }
  .list-01 {
    li {
      margin-bottom: 1.5em;
    }
  }
}

.sitemap-page {
  .main-contents {
    .cols {
      justify-content: space-between;
      .col {
        width: 33.33%;
      }
    }
    .category {
      &::after {
        display: block;
        margin-top: 32px;
        margin-bottom: 32px;
        width: 16px;
        height: 1px;
        background-color: #ddd;
        content: "";
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }
      a {
        display: inline-block;
      }
      li {
        margin-bottom: 16px;
      }
      > li {   
        > a{          
          color: $blue;
          font-size: 1.8rem;
        }
        ul {
          margin-top: 24px;
          li {
            &::before {
              display: inline-block;
              margin-right: 8px;
              width: 5px;
              height: 10px;
              background: url(../images/icon_arrow_01.svg) no-repeat center center;
              background-size: contain;
              content: "";
            }
          }
        }
      }
    }
  }
}

.cases-page {
  .main-contents {
    .boxes {
      display: flex;
      flex-wrap: wrap;
      margin-right: -20px;
      margin-left: -20px;
      .box {
        position: relative;
        margin-bottom: 40px;
        padding: 0 20px;
        width: 33.33%;
        .inner {
          position: relative;
          padding: 24px;
          height: 100%;
          background-color: #f8f8f8;
          border-radius: 8px;
          // box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.1);
          // &::before {
          //   display: block;
          //   height: 4px;
          //   width: 100%;
          //   position: absolute;
          //   left: 0;
          //   top: 0;        
          //   background: $blue; /* Old browsers */
          //   background: $blueGradient;
          //   content: "";
          // }          
        }
        .project-name {
          margin-top: 0;
          margin-bottom: 14px;
          color: $darkBlue;
          font-size: 2rem;
          line-height: 1.4em;
          &::after {
            margin-top: 12px;
          }
        }
        .project-note {
          margin-top: 20px;
          font-size: 1.3rem;
        }
      }
    }
  }
}

.contact-page {
  .main-contents {
    .lead {
      font-size: 1.8rem;
    }
    .subhead {
      font-size: 2.4rem;
    }
    .phone-number {
      margin: 32px 0 0 0;
      font-size: 1.3rem;
      strong {
        margin-right: 8px;
        font-family: 'roboto';
        font-size: 2.4rem;
        font-weight: 600;
        // &::before {
        //   display: inline-block;
        //   position: relative;
        //   top: 3px;
        //   margin-right: 6px;
        //   width: 3px;
        //   height: 20px;
        //   background: $blueVerticalGradient;
        //   content: "";
        // }    
      }
    }    
    .dl-table {
      margin: 32px auto 40px auto;
      dt {
        padding: 24px;
        width: 240px;
      }
      dd {
        padding: 24px;
        width: calc(100% - 240px);
      }
    }
    input[type="text"],
    textarea{
      padding: 8px 16px;
      max-width: 400px;
      width: 100%;
      font-size: 1.6rem;
      line-height: 1.5em;
      border: 1px solid #ddd;
      border-radius: 8px;
      &:focus {
        border-color: #adc0f3;
        outline: none;
      }
    }
    textarea {
      max-width: none;
    }
    .required {
      color: $red;
      font-size: 1.8rem;
    }
    .contact-footer {
      text-align: center;
    }
    .agreement {
      margin: 40px auto;
      padding: 24px;
      max-width: 400px;
      background-color: #fcefef;
      border-radius: 8px;
    }
    .actions {
      text-align: center;
      .button-01 {
        margin: 0 auto;
        padding: 16px 40px;
        min-width: 240px;
        font-size: 1.6rem;
      }
    }
  }
}


.news-list {
	margin-bottom: 40px;
	border-top: 1px solid #e2e2e2;
  li {
    border-bottom: 1px solid #e2e2e2;
  }
  a {
    &:link, &:visited {
      display: flex;
      flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      padding-left: 20px;
      padding-right: 20px;
      color: #333;
    }
    &:hover, &:active {
      opacity: 1;
	    background-color: #eff4fc;
    }
  }
  .date {
    padding: 30px 0 30px 0;
    width: 120px;
    flex:0 0 auto;
    -ms-flex:0 0 auto;
  }
  .categories {
    flex:0 0 auto;
    padding: 30px 20px;
    text-align: left;
    white-space: nowrap;
    font-size: 0;
    word-wrap: none;
    span {
      display: inline-block;
      margin: 0 2px;
      padding: 3px 16px;
      width: 120px;
      color: #fff;
      font-size: 1.2rem;
      line-height: 1.6em;
      text-align: center;
      background-color: #00a27a;
      &.important {
        width: 60px;	
        background-color: #d63636;
      }
    }    
  }  
  .title {
    flex: 1 1 auto;
    padding-top: 30px;
    padding-bottom: 30px;		
    font-size: 1.6rem;    
  }  
}


.news-page {
  .news-list {
    margin-top: 40px;
  }
  .post {
    h1 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 1.5em;
    }    
    .post-meta {
      p {
        display: inline-block; 
      }
    }    
    .post-body {
      margin-top: 20px;
      line-height: 1.8em;
      letter-spacing: 0.05em;
      p {
        margin-bottom: 1.5em;
      }
    }    
  }
}

